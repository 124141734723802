@import 'assets/theme/theme';
@import 'assets/css/variables';

@font-face {
  font-family: open-sans;
  src: url(../src/assets/fonts/OpenSans-Regular.ttf);
}

*:not(mat-icon) {
  font-family: open-sans, serif !important;
  font-size: var(--console-font-size);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(206, 210, 212, 0);
  border-radius: 4px;
}

*:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #ced2d4;
  }
}

.mat-icon {
  font-size: 20px;
}

.container {
  background: var(--console-page-background);
}

.mat-drawer-content {
  background: var(--console-page-background) !important;
}

.account-container {
  background: url('./assets/images/bottom-bg.png');
  background-position-y: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: auto;
  background-size: 100%;
}

.right {
  float: right !important;
}

.color-primary {
  color: #607d8b !important;
}

.color-red {
  color: #607d8b !important;
}

.color-white {
  color: #fff !important;
}

.BCFP {
  display: flex;
  justify-content: flex-end;
  min-width: 96% !important;
  max-width: 96% !important;
  min-height: 96%;
  max-height: 96%;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.BCFP mat-dialog-container {
  padding: 0 !important;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

.BCFP mat-dialog-container mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  max-height: 96vh !important;
  height: 96vh;
}

.BCFP mat-dialog-container mat-dialog-content iframe {
  width: 100%;
  height: 100%;
}

.TAVTIMG {
  height: 50px;
  width: 50px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.margin-top-12px {
  margin-top: 12px;
}

.example-container {
  height: 100vh;
}

.side-nav-my {
  overflow: auto;
  border-right: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  padding: 0 !important;
}

.avatar {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
  background-color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-title {
  color: #607d8b !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-disabled {
  opacity: 0.5;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: var(--console-page-background);
}

.cursor-pointer {
  cursor: pointer;
}

.pl-10 {
  padding-left: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.font-weight-600 {
  font-weight: 600;
}

.paginator {
  background: var(--console-page-background);
}

.mat-mdc-paginator-page-size {
  position: fixed;
  right: 4vw;
}

.mat-mdc-paginator-icon {
  border-radius: 4px;
}

.mat-mdc-paginator-container {
  margin-right: 59vw;
  height: 10px;
}

.bold-capitalized {
  font-weight: 600;
  text-transform: capitalize;
}

.logs-divider {
  border-bottom: 1px solid #e0e1e2;
  margin: 5px 0;
}

.d-flex {
  display: flex;
}

.flex-3 {
  flex: 3;
}

.flex-1 {
  flex: 1;
}

@import './new-design';
@import 'assets/css/grid';
@import 'assets/css/table';
@import 'assets/css/buttons';
@import 'assets/css/override';
@import 'assets/css/page-structure';
@import 'assets/css/input-fields';
@import 'assets/css/sidebar';
