@import './variables';
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  letter-spacing: -0.01em;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  gap: 8px;

  &.small {
    height: 32px;
    padding: 0 10px;
  }

  &[disabled] {
    opacity: 0.6;
  }

  &:not([disabled]):not(.clear-button):hover {
    box-shadow: 0 0 1px 1px #e9ebec;
  }

  &.clear-button {
    background: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #4576ff;
  }

  &.icon-only {
    width: 40px;
    padding: 0;

    &.small {
      width: 32px;
    }
  }
}

.primary-button {
  background: $general-primary-button-color;
  color: #fdfdfd;

  &:disabled {
    cursor: auto;
  }
}

.secondary-button {
  background: #ecedee;
  color: $general-text-color;

  .fas {
    color: $general-text-color;
  }
}

.accent-button {
  background: $general-orange-color;
  color: #fdfdfd;
}
