@import './variables';
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-header {
  border-bottom: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels {
  gap: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label {
  opacity: 1 !important;
  height: 32px !important;
  min-width: max-content !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-content {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $general-text-color;
  opacity: 0.6;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-active .mat-tab-label-content {
  opacity: 1 !important;
  font-weight: 600 !important;
}

.mat-mdc-slide-toggle {
  height: 20px !important;
  line-height: 20px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-bar {
    height: 20px;
    width: 32px;
    border-radius: 10px;
    background-color: #c5c9cb !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-thumb {
    height: 16px;
    width: 16px;
    top: 5px;
    position: relative;
    right: -2px;
  }

  .toggle-label,
  .divider {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $general-text-color;
    opacity: 0.6;
  }

  .active-toggle-label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $general-text-color;
  }

  .hint {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $general-text-color;
    opacity: 0.6;
    padding-left: 24px;
  }
}

.mat-mdc-slide-toggle.mat-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-bar {
    background-color: #37474f !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-thumb {
    background-color: #ffffff !important;
    right: 2px;
  }

  &.mat-accent {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
      background-color: #f15d32 !important;
    }
  }
  &.mat-warn {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
      background-color: #5b16f6 !important;
    }
  }
  &.mat-blue {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
      background-color: #4576ff !important;
    }
  }
  &.mat-lightBlue {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
      background-color: #25bbe3 !important;
    }
  }
}

mat-form-field.mat-mdc-form-field {
  margin-top: calc(0.84375em - 2px);
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: -0.01em !important;
  color: $general-text-color !important;

  .mat-mdc-form-field-flex {
    .mdc-floating-label {
      top: 16px;
    }
  }

  .mat-mdc-form-field-infix {
    border-top: 2px solid transparent !important;
    min-height: 32px;
    padding: 0 !important;
    display: flex;
    box-sizing: border-box;

    textarea {
      margin: 4px 0;
      height: 18px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    mat-select {
      padding: 8px 0 0 !important;
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-arrow-wrapper {
        position: relative;
        top: 4px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-min-line {
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
  }
}

.datepicker.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    margin: 2px 0 !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 30px !important;
  }

  .mat-mdc-form-field-icon-suffix {
    height: 30px;

    .mdc-icon-button {
      height: 25px;
      padding: 0;
    }
  }
}

.datepicker.report-page.mat-mdc-form-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-mdc-radio-group {
  height: 40px;
  display: inline-flex;
  gap: 24px;
  align-items: center;

  .mat-mdc-radio-button {
    height: 40px;
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label {
      vertical-align: unset;
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      .mat-radio-container {
        height: 16px;
        width: 16px;
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-outer-circle {
          border-color: #9ea5a8 !important;
          opacity: 0.5;
          height: 16px !important;
          width: 16px !important;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      .mat-radio-label-content {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: $general-text-color;
        opacity: 0.6;
        padding-left: 8px;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-ripple {
      display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    &.mat-radio-checked {
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      .mat-radio-label {
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-container {
          /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
          .mat-radio-outer-circle {
            border-color: #35474e !important;
            border-width: 5px;
            opacity: 1;
          }
          /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
          .mat-radio-inner-circle {
            display: none;
          }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-label-content {
          opacity: 1;
        }
      }
    }
  }
}

.mat-mdc-checkbox {
  height: 14px !important;
  line-height: 14px;

  .mdc-checkbox__background {
    width: 14px !important;
    height: 14px !important;
    border-color: #9ea5a880 !important;
    top: 7px;
  }
  .mdc-label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    height: 25px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: $general-text-color;
  }
}

.NgxEditor__Wrapper {
  border: 1px solid #e9ebec !important;
  border-radius: 4px !important;

  .NgxEditor__MenuBar {
    border-bottom: 1px solid #e9ebec !important;
  }
}

.mat-sort-header-container.mat-focus-indicator.mat-sort-header-sorted {
  border-bottom: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select {
  &.expanded-select {
    height: auto !important;
    min-height: 32px;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-trigger {
      height: auto;
      min-height: 1.125em;
      display: flex;
      padding: 8px 0 3px;
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-min-line {
        white-space: normal !important;
      }
    }
  }
}

.paginator-container .right-content mat-select {
  min-width: 40px;
}

.mat-mdc-select-panel {
  padding: 0 !important;
  .mat-mdc-option {
    padding: 0 8px !important;
    min-width: 40px;

    &:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
      display: none;
    }
  }

  .search-container {
    margin: 10px;
    position: relative;

    input {
      padding-left: 28px;
      background: #ffffff;
      border: 1px solid #e5e9ee;
      border-radius: 4px;
      width: 100%;
      display: block;
      box-sizing: border-box;
      height: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: $general-text-color;
      outline: none;
      transition: all 200ms;

      &:focus {
        border: 1px solid #35474e;
      }

      &::placeholder {
        opacity: 0.6;
      }
    }

    &::before {
      content: url('../images/search-icon.svg');
      position: absolute;
      top: 8px;
      left: 8px;
    }

    &:has(input:focus) {
      &:before {
        content: url('../images/search-icon-active.svg');
      }
    }
  }

  .select-all-container {
    margin: 10px 0;

    .mat-mdc-checkbox {
      .mdc-label {
        font-weight: 600;
        height: 25px;
      }
    }
  }

  .options-container {
    overflow: auto;
    height: 200px;
  }
}

.api-select-panel {
  max-height: 300px !important;
}

.filter-action-select {
  min-width: 300px !important;
}

.filter-action-select,
.mat-mdc-select-panel {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15) !important;
  border-radius: 4px;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  transform: translateX(-8px) translateY(24px) !important;
  width: fit-content !important;
}

.cdk-overlay-pane:has(.filter-action-select) {
  transform: translateX(-8px) translateY(28px) !important;
}

.mat-mdc-option-pseudo-checkbox {
  margin-right: 12px !important;
  height: 14px !important;
  width: 14px !important;
  color: #9ea5a880;
}

.mat-pseudo-checkbox-checked {
  background: #35474e !important;

  &::after {
    width: 6px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
  color: #9ea5a8 !important;
  margin-left: 8px !important;
}

.mat-mdc-dialog-container {
  box-shadow: -4px 10px 20px rgba(42, 43, 44, 0.05) !important;
  border-radius: 8px !important;
  min-width: fit-content !important;
}

.mat-sort-header {
  .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0) !important;

    .mat-sort-header-stem {
      display: none;
    }

    .mat-sort-header-indicator {
      transform: translateY(0) !important;

      &::before {
        content: url('../images/sort-icon.svg');
        position: absolute;
        top: -1px;
      }

      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }
}

.mat-mdc-tooltip,
.mat-mdc-snack-bar-container {
  user-select: none;
  border-radius: 4px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.01em !important;
  color: $general-text-color !important;
  padding: 10px 16px !important;

  .mdc-snackbar__surface {
    background: #ffffff !important;
    color: $general-text-color;

    .mat-mdc-snack-bar-label {
      color: $general-text-color;
    }

    .mat-mdc-snack-bar-action {
      color: $general-text-color !important;
    }
  }
}

.image-uploader-model {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

.ngx-file-drop__drop-zone {
  border: none !important;
  height: unset !important;
  border-radius: 0 !important;

  .ngx-file-drop__content {
    height: unset !important;
    display: block !important;
    padding: 22px !important;
  }
}

.cdk-overlay-pane {
  &:has(.custom-menu-panel) {
    .custom-menu-panel {
      background: #ffffff;
      box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15);
      border-radius: 4px;
      min-width: 100px;

      .mat-mdc-menu-content {
        padding: 9px 0;

        .mat-mdc-menu-item {
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: $general-text-color;
          padding: 7px 10px;
          height: unset;
        }
      }
    }
  }

  &:has(.send-message-menu) {
    transform: translate(-72px, -10px);

    .send-message-menu {
      background: #ffffff;
      box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15);
      border-radius: 4px;

      .mat-mdc-menu-content {
        padding: 16px;

        .heading {
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.01em;
          color: $general-text-color;
        }

        .buttons {
          display: flex;
          gap: 12px;
          margin-top: 8px;

          .button {
            height: 28px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px !important;
            line-height: 16px;
            text-align: center;
            letter-spacing: -0.01em;
            color: $general-text-color;
            padding: 0 8px;

            &.back {
              background: none;
              margin-left: -8px;
            }

            &.approve {
              background: #ecedee;
            }
          }
        }
      }
    }
  }

  &:has(.nested-select) {
    transform: translate(-8px, 16px);

    .nested-select {
      background: #ffffff;
      box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15);
      border-radius: 4px;
      max-height: 500px !important;
      max-width: unset !important;
      min-width: 400px;
      overflow-x: hidden;

      .mat-mdc-menu-content {
        padding: 10px;

        .item {
          height: 30px;
          display: flex;
          align-items: baseline;
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
          mat-checkbox {
            flex: 1;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
            .mat-checkbox-inner-container {
              margin-right: 12px !important;
            }
            .mdc-label {
              font-style: normal;
              font-weight: 600;
              height: 25px;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.01em;
              color: $general-text-color;
            }
          }

          .label {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: $general-text-color;
            flex: 1;
            user-select: none;
            margin: 0 -32px 0 -10px;
            padding: 0 32px 0 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-transform: capitalize;
          }

          .expand-button {
            background: none;
            padding: 0;
            height: 30px;
            width: 30px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            margin-right: -10px;
            justify-content: center;
            border: none;
            cursor: pointer;
            user-select: none;

            img {
              transition: all 200ms;
            }
          }
        }

        .nested-list {
          margin-left: 25px;
          display: none;

          .nested-item {
            height: 30px;
            display: flex;
            align-items: center;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
            .mat-checkbox-inner-container {
              margin-right: 12px !important;
            }
            .label,
            .mdc-label {
              font-style: normal;
              font-weight: 400;
              height: 25px;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.01em;
              color: $general-text-color;
              text-transform: capitalize;
            }

            .label {
              height: 100%;
              display: flex;
              align-items: center;
              width: 100%;
              cursor: pointer;
              user-select: none;
              margin-left: -25px;
              padding-left: 25px;
              flex: 1;
              text-transform: capitalize;
            }
          }
        }

        .expanded {
          .expand-button {
            img {
              transform: rotate(180deg);
            }
          }

          .nested-list {
            display: block;
          }
        }
      }
    }
  }
}

.mat-calendar {
  &.custom-calendar {
    background: #f4f4f4;
    border-radius: 10px;
    padding: 18px 16px 22px;

    .mat-calendar-header {
      padding: 0;

      .mat-calendar-controls {
        margin: 0;
        align-items: center;
        gap: 24px;

        .mat-calendar-spacer {
          display: none;
        }

        .mat-calendar-period-button {
          order: 1;
          padding: 0 20px;
          width: 150px;
          /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
          .mat-button-wrapper {
            transform: translateY(-2px);

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              text-transform: lowercase;
              display: inline-block;

              &::first-letter {
                text-transform: uppercase !important;
              }
            }

            .mat-calendar-arrow {
              display: none;
            }
          }
        }

        .mat-calendar-previous-button,
        .mat-calendar-next-button {
          height: 32px;
          width: 32px;

          &::after {
            content: url(../images/arrow.svg);
            border: none;
            margin: 0;
            transform: none;
          }
        }

        .mat-calendar-previous-button {
          order: 0;

          &::after {
            transform: rotateY(180deg);
          }
        }

        .mat-calendar-next-button {
          order: 2;
        }
      }
    }

    .mat-calendar-content {
      padding: 0 !important;

      mat-month-view,
      mat-multi-year-view,
      mat-year-view {
        .mat-calendar-table {
          .mat-calendar-table-header {
            th {
              height: 32px;
              width: 32px;
              padding: 0;

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #3c3e4c;
              }
            }

            .mat-calendar-table-header-divider {
              display: none;
            }
          }

          .mat-calendar-body {
            .mat-calendar-body-label {
              opacity: 0;
            }

            .mat-calendar-body-cell-container {
              .mat-calendar-body-cell {
                .mat-calendar-body-cell-content {
                  height: 32px;
                  width: 32px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #3c3e4c;

                  &.mat-calendar-body-today {
                    font-weight: 600;
                    color: #35474e;
                    border: none;
                  }

                  &.mat-calendar-body-selected {
                    color: #ffffff;
                    background: #35474e;
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }

      mat-multi-year-view,
      mat-year-view {
        .mat-calendar-table {
          .mat-calendar-body {
            .mat-calendar-body-cell-container {
              .mat-calendar-body-cell {
                .mat-calendar-body-cell-content {
                  width: 56px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.duplicate-company-dialog {
  .mat-mdc-dialog-container {
    box-shadow: -4px 10px 20px rgba(42, 43, 44, 0.05) !important;
    border-radius: 8px !important;
    padding: 0 !important;
  }
}

.mat-mdc-card {
  padding: 16px;

  .mat-mdc-card-title {
    font-size: 24px;
    font-weight: 500;
  }
}

.mat-mdc-select {
  .mat-mdc-select-arrow-wrapper {
    margin-left: 4px;
  }
}

.mdc-list-item__primary-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

div .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
  &:hover {
    background-color: unset !important;
  }
}

.mdc-tooltip__surface {
  background-color: var(--console-white-color) !important;
  color: var(--console-color) !important;
  padding: 10px 15px !important;
}

.mat-mdc-icon-button {
  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    padding: 0;

    .mat-mdc-button-persistent-ripple,
    .mat-ripple {
      display: none;
    }
  }

  img {
    width: 12px !important;
    height: 12px !important;

    &:hover {
      overflow: hidden;
    }
  }
}

.mat-datepicker-toggle {
  img,
  svg {
    width: 16px !important;
    height: 16px !important;

    &:hover {
      overflow: hidden;
    }
  }
}

.mat-mdc-slide-toggle .mdc-switch__ripple::after,
.mat-mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-radio-button .mdc-radio__background::before,
.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
  background: none !important;
}

.view-company .tab-container .mat-mdc-tab-header {
  padding-right: 36px;
}
