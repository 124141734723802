.hide {
  display: none !important;
}

.spacer {
  flex: 1;
}

.mat-mdc-option {
  line-height: unset !important;
  height: auto !important;
  min-height: 3em;

  .secondary-text {
    font-size: 10px;
    opacity: 0.6;
  }
}

.view-user {
  .mat-sort-header-container {
    .mat-sort-header-content {
      margin-right: 32px !important;
    }
  }
}
