$general-border: 1px solid #f0f0f0;
$general-text-color: #2a2b2c;
$general-box-shadow: 0 4px 20px rgba(42, 43, 44, 0.05);
$general-purple-color: #9a21f4;
$general-silver-color: #ccc;
$general-white-color: #fff;
$general-orange-color: #f78d5f;
$general-blue-color: #4576ff;
$general-red-color: #dc3545;
$secondary-text-color: #7f8080;
$sidebar-bg-color: #35474e;
$general-chips-background-color: #e0e0e0;
$general-chips-text-color: #212121;
$general-primary-button-color: #35474e;
