@import './variables';
.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .back-button {
    margin-left: 35px;
    outline: none;
    border: none;
    display: flex;
    border-radius: 4px;
    height: 22px;
    padding: 0;
    cursor: pointer;
    position: fixed;
    top: 19px;
  }

  .page-header {
    position: relative;
    margin: 24px 35px 16px;

    .breadcrumb-heading {
      display: inline-block;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.01em;
      color: $general-text-color;
    }
  }

  .page-actions {
    display: flex;
    align-items: center;
    margin: 0 35px 20px;
    gap: 10px;
    flex-wrap: wrap;

    .search {
      background: #ffffff;
      border-radius: 4px;
      height: 32px;
      display: flex;
      align-items: center;
      width: 200px;
      position: relative;
      padding-left: 28px;

      input {
        height: 32px;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: $general-text-color;

        &::placeholder {
          opacity: 0.6;
        }
      }

      &::before {
        content: url('../images/search-icon.svg');
        position: absolute;
        top: 8px;
        left: 8px;
      }

      &:has(input:focus) {
        &:before {
          content: url('../images/search-icon-active.svg');
        }
      }
    }

    .select {
      width: 184px;
      background: #ffffff;
      border-radius: 4px;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: $general-text-color;

      app-nested-select {
        width: 100%;

        .mat-mdc-menu-trigger {
          .label {
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: $general-text-color;
          }
        }
      }

      &.large {
        flex: 1;
      }
    }

    .spacer {
      flex: 1;
    }
  }

  .page-content {
    flex: 1;
    padding: 0 35px 20px;
    overflow: auto;
    position: relative;

    .heading {
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: $general-text-color;
    }

    form {
      position: relative;
    }

    .row {
      margin: 0 -8px;

      .col {
        padding: 0 8px;

        mat-form-field {
          width: 100%;
          display: block;
        }

        .ng-select {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .page-footer {
    position: relative;
    bottom: 0;
    padding: 20px 36px;
    background: #ffffff;
    box-shadow: 0 -1px 0 #e5e9ee;
  }
}
