@import 'variables';
.sidebar {
  mat-list-item {
    max-height: 40px !important;
    .mdc-list-item__content {
      max-height: 40px;
      border-radius: 4px;
      color: #fff;
      font-size: 12px !important;

      span {
        color: var(--console-text-color);
        font-size: 12px !important;
      }

      &:hover {
        color: var(--console-text-color) !important;
        background: #ffffff30 !important;
      }
    }

    &:focus,
    &:hover {
      background: none;
    }
  }

  .mat-expansion-panel {
    user-select: none;
    background: transparent;
    border-radius: 4px;
    box-shadow: none !important;

    .mat-expansion-panel-header {
      padding: 0 6px 0 12px;
      height: 32px;

      .mat-content {
        gap: 12px;

        .line-icon {
          &.active {
            display: none;
          }

          &.inactive {
            display: block;
          }
        }

        .line-text {
          font-style: normal;
          font-weight: 600;
          font-size: 12px !important;
          line-height: 16px;
          letter-spacing: -0.01em;
          text-transform: uppercase;
          color: #ffffff;
        }
      }

      .mat-expansion-indicator {
        display: flex;
        align-items: center;
        &::after {
          color: #ffffff;
          padding: 2px;
        }
      }

      &:focus {
        background: none !important;
      }

      &:hover {
        background: #ffffff30 !important;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 !important;

        mat-nav-list {
          padding: 5px 0;
          mat-list-item {
            padding: 0 !important;
            height: auto !important;
            .mdc-list-item__content {
              padding: 4px 12px 6px 40px;

              .text {
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 16px;
                letter-spacing: -0.01em;
                color: #ffffff;
                text-transform: lowercase !important;

                &:first-letter {
                  text-transform: capitalize !important;
                }
              }
            }
          }
        }
      }
    }

    &.mat-expanded {
      background: #ffffff30;
      margin: 4px 0;

      .mat-expansion-panel-header {
        &:hover {
          background: none !important;
        }
      }
    }

    &.has-selected-item {
      background: #ffffff;

      .mat-expansion-panel-header {
        .mat-content {
          .line-icon {
            &.active {
              display: block !important;
            }

            &.inactive {
              display: none !important;
            }
          }

          .line-text {
            font-style: normal;
            font-weight: 600;
            font-size: 12px !important;
            line-height: 16px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
            color: $general-text-color;
          }
        }

        .mat-expansion-indicator {
          &::after {
            color: #9ea5a8;
          }
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          mat-nav-list {
            mat-list-item {
              .mdc-list-item__content {
                .text {
                  color: $general-text-color;
                  opacity: 0.6;
                }
              }

              &.selected-item {
                .mdc-list-item__content {
                  .text {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .line-icon {
    border-radius: 0 !important;
    height: 16px !important;
    width: 16px !important;

    &.active {
      display: none;
    }

    &.inactive {
      display: block;
    }
  }
}
