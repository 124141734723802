@import './variables';

.table {
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  width: 100%;
  display: table;
  background: white;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    background: #f9f9f9;
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    height: calc(100% - 30px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;

    tr {
      display: table;
      width: calc(100% + 8px);
      table-layout: fixed;
    }
  }

  tr {
    border-bottom: $general-border;

    th {
      padding: 8px 4px !important;

      &.text-center {
        .mat-sort-header-container {
          justify-content: center;
        }
      }
    }

    th,
    .mat-sort-header-content {
      white-space: nowrap !important;
      user-select: none;
      font-style: normal;
      font-weight: 400;
      font-size: 10px !important;
      line-height: 14px;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      color: #2a2b2c99;
    }

    th,
    td {
      cursor: pointer;
      border: none;
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      box-sizing: border-box;
      border-radius: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    td {
      min-height: 44px;
      height: 44px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: $general-text-color;
      padding: 4px;
    }

    th:first-child,
    td:first-child {
      padding-left: 20px !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 20px !important;
    }

    &:last-child {
      margin-bottom: 10px;
      border-bottom: none;
    }

    &:hover {
      background: #35474e1a;
    }

    &:active,
    &.selected {
      background: #35474e1a;
    }

    &.deactivate {
      opacity: 0.5;
    }
  }

  .avatar-cell {
    padding: 0 16px 0 !important;
    width: 66px;
    position: relative;

    .online {
      height: 12px;
      width: 12px;
      background: var(--accent-color);
      border-radius: 50%;
      position: absolute;
      top: 4px;
    }

    img {
      height: 24px;
      width: 24px;
      border-radius: 4px;
    }
  }

  .selector-cell {
    padding: 0 16px 0 0 !important;
    width: 50px;
    text-align: center;
  }
}
